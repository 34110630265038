import Lottie from "lottie-react";
import tiktok from "./lotties/tiktok.json";
import youtube from "./lotties/youtube.json";
import instagram from "./lotties/instagram.json";

export default function Socials({ center = false }) {
    return (
        <div className={` flex gap-1 mt-8 ${center ? 'justify-center':''}`}>
            <a href="">
                <Lottie animationData={instagram} loop={2} className='h-16' />
            </a>

            <a href="">
                <Lottie animationData={youtube} loop={2} className='h-16' />
            </a>

            <a href="">
                <Lottie animationData={tiktok} loop={2} className='h-16' />
            </a>

        </div>
    )
}