import Lottie from "lottie-react";
import coming1 from "./lotties/coming1.json";
import coming2 from "./lotties/coming2.json";

export default function ComingSoon() {

    return (
        <div className=" relative h-60 md:h-[300px] flex items-center justify-center overflow-hidden " >

            <Lottie animationData={coming1} loop={true} className=' h-96 md:h-[50rem]' />

        </div>

    )


}