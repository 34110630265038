import './App.css';
import Lottie from "lottie-react";
import brushStroke from "./lotties/brush-stroke.json";
import Socials from './Socials';
import Gallery from './Gallery';
import ComingSoon from './ComingSoon';


function App() {
  return (
    <div className="App">

      <div className=' h-[85vh] flex justify-center items-center ' style={{
        backgroundImage: 'url(/images/image1.JPG)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundColor: '#FFD957',
        backgroundBlendMode: 'darken'
      }}>

        <div className=' p-4 md:p-12 lg:px-20 flex flex-col rounded-lg shadow-md w-[95%] h-[95%] bg-[#F3F1EB] max-w-[1200px]'>

          <div className=' flex justify-center '>
            <img src="/images/logo.png" alt="Logo" className=' w-[200px] ' />
          </div>

          <div className=' flex-1 flex flex-col md:flex-row'>


            <div className='flex flex-col justify-center  md:h-full md:w-[60%] '>
              <h1 className=' doodleFont text-7xl md:text-8xl tracking-wider text-center md:text-left'>IN HER</h1>
              <div className='flex flex-col relative items-center md:items-start'>
                <h1 className='doodleFont text-7xl md:text-8xl tracking-wider text-[#FF8111] '>OPINION</h1>
                <Lottie animationData={brushStroke} loop={true} className=' absolute w-80 h-80 -top-28   md:-top-24 md:-left-10 ' />
              </div>
              <p className='text-xs tracking-[1em] mt-2 text-center md:text-left'>WITH WENDY AND ANDREA</p>

              <p className=' caveatfont text-md leading-6 mt-4 mb-4 md:mt-8 tracking-wider italic text-center md:text-left '>A podcast celebrating the voices and perspectives of the African millennial woman </p>

            </div>

            <div className=' hidden md:block md:cta-image-md w-16 relative z-50 -right-14 ' ></div>
            <div className=' relative flex justify-end flex-1 rounded-lg bg-no-repeat bg-cover bg-center  ' style={{
              backgroundImage: 'url(/images/BG1.JPG)'
            }} >

            </div>

          </div>

        </div>


      </div>


      <Socials center />

      <ComingSoon />

      <Gallery />

    </div>
  );
}

export default App;
