import React from 'react';
import ScrollCarousel from 'scroll-carousel-react';


const Gallery = () => {
    return (
        <div className=' p-6'>
            <h1 className=' text-center text-3xl tracking-wide font-bold mb-4 '>FROM THE STUDIO </h1>

            <ScrollCarousel
                autoplay
                autoplaySpeed={2}
                speed={0.1}
                onReady={() => console.log('I am ready')}
            >
                {[...Array(32)].map((_, item) => (
                    <div key={item} className='bg-blue-300/20 border-2 border-blue-300/70 rounded h-36 w-48' style={{
                        backgroundImage: `url(/gallery/${Math.floor(Math.random() * 31) + 1}.JPG)`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }}>

                    </div>
                ))}
            </ScrollCarousel>
        </div>
    );
};

export default Gallery;